import { useEffect, useState } from "react";
import * as Const from './Constants';

const items = ["Publications",
               "Teaching",
               "Games & Puzzles",
               "Positions",
               "",
               "Arts & Maths (STEAM)",
               "Advising & Mentoring",
               "Outreach & Education",
               "Talks"]

const nrows = 3;
const ncols = 3;
const init = 1;

function Menu(props) {
    var min = Math.min(props.size.width,props.size.height);
    var width = (props.timeLeft>15) ? Math.min(900,Math.max(500,min*0.9)) : 402;
    var fs = (props.timeLeft>15) ? "1.8em" : "1.2em";

    var indents = [];
    for (var i = 0; i < nrows; i++) {
        indents.push(<Row id={i} key={i+init}
                          items={items.slice(i*ncols,(i+1)*ncols)}
                          width={width}
                          timeLeft={props.timeLeft} gotoNext={props.gotoNext}/>);
    }
    return (
        <div className="MenuTop" style={{width: (props.timeLeft>15) ? props.size.width : width, fontSize:fs}}>
            <div className={"Menu fade-in"+((props.timeLeft>15) ? "" : " active")} style={{width: width}}>
                {indents}
            </div>
        </div>
    );
}

function Row(props) {
    var indents = [];
    for (var i = 0; i < ncols; i++) {
        indents.push(<Item id={(i*nrows)+props.id} key={i+props.id}
                           item={props.items[i]}
                           width={props.width}
                           timeLeft={props.timeLeft} gotoNext={props.gotoNext}/>);
    }

    return (
        <div className="row" style={{width: props.width/ncols}}>
            {indents}
        </div>
    );
}

const origin = {0: "top left",
                1: "top center",
                2: "top right",
                3: "center left",
                4: "center center",
                5: "center right",
                6: "bottom left",
                7: "bottom center",
                8: "bottom right"};

const order = {0: 100,
               1: 91,
               2: 82,
               3: 37,
               4: 28,
               5: 73,
               6: 46,
               7: 55,
               8: 64
              };

function Item(props) {
    const [dis,setDis] = useState(true);

    function update(){
        if((!props.timeLeft) && (props.id !== 4)){
            var center = document.getElementsByClassName("it4")[0];
            center.style.transformOrigin = origin[props.id];
            center.className += " hov";
            center.innerHTML = "";
        }
    }

    function timer(){
        setTimeout(() => {
            remove();
          }, 1000);
    }

    function remove(){
        var center = document.getElementsByClassName("it4")[0];
        center.className = center.className.replace(" hov","");
        center.innerHTML = "<p><i>β<sub>1</sub> = 1</i></p>";
    }

    useEffect(() => {
        if(props.timeLeft < Const.t_tile_anim[props.id]){
            setDis(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.timeLeft]);

    var active = (props.id!==4) && !props.timeLeft;

    var textIt4 = (props.timeLeft>(Const.t_tile_anim[3]-15))? <p><i>β<sub>1</sub> = 0</i></p>:
                                                              <p><i>β<sub>1</sub> = 1</i></p>;
    var text = (props.id===4)? textIt4 :
                active?  <p>{props.item}</p> :
                            <p></p>;

    return (
      <div className={(dis? "dis ": "fade-in ")+"it"+props.id+" "+(active ? "item item-active" : "item")} key={props.id}
            style={{height: props.width/ncols,
                    width: props.width/nrows}}
            onMouseEnter={active?update:()=>{}}
            onMouseLeave={active?remove:()=>{}}
            onTouchStart={active?timer:()=>{}}>
          {text}
      </div>
    );
}

export default Menu;
