import './App.css';
import Main from './Main';
import {Header} from './Main';
import {Footer} from './Main';
import * as Const from './Constants';

import {useState,useEffect} from 'react';

function App() {
  const size = useWindowSize();
  const [timeLeft, setTimeLeft] = useState(Const.t_anim);
  const [next,setNext] = useState(false);

  useEffect(() => {
      if((timeLeft<17)&&(!next)){
        return;
      }
      if(timeLeft===0){
         setTimeLeft(null)
      }
  
      if (!timeLeft) return;
  
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 100);
  
      return () => clearInterval(intervalId);
    }, [timeLeft,next]);

  return (
    <div className="App">
      {(timeLeft<15) && <Header size={size} timeLeft={timeLeft}/>}
      <Main size={size} timeLeft={timeLeft} gotoNext={()=>{setNext(true)}}/>
      {(timeLeft<15) && <Footer size={size} timeLeft={timeLeft}/>}
      {(timeLeft<(Const.t_tile_anim[3]-15)) && (timeLeft>15) &&
                <div onClick={()=>{setNext(true)}} className={"next fade-in2"}>
                    <p>Next</p>
                </div>
            }
    </div>
  );
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 10,
    height: 10,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default App;
