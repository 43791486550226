export const t_anim = 70;
export const t_tile_delay = 2;
export const t_form_delay = 10;
export const t_tile_anim = [t_anim - t_form_delay - t_tile_delay,
                            t_anim - t_form_delay - t_tile_delay*2,
                            t_anim - t_form_delay - t_tile_delay*3,
                            t_anim - t_form_delay - t_tile_delay*8,
                            t_anim,
                            t_anim - t_form_delay - t_tile_delay*4,
                            t_anim - t_form_delay - t_tile_delay*7,
                            t_anim - t_form_delay - t_tile_delay*6,
                            t_anim - t_form_delay - t_tile_delay*5,
                            ];
