import Menu from './Menu';

function Main(props) {
    var height = (props.timeLeft>15)? props.size.height : "auto";

    return (
        <div className="Main" style={{width: "100%",height: height}}>
            <Top timeLeft={props.timeLeft} size={props.size} gotoNext={props.gotoNext}/>
        </div>
    );
}

function Header(props){
    var height = (props.timeLeft>13)? "0px" : "50px";
    var padding = (props.timeLeft>13)? "0px" : "20px";
    var fs = (props.timeLeft>13)? "0" : "1em";
    return (
        <div className="header" style={{height: height,paddingTop: padding,paddingBottom: padding, fontSize:fs, width: "100%"}}>
            <h1>Érika Roldán, Ph.D.</h1>
            <p>Contact</p>
        </div>
    );
}

function Footer(props){
    var height = (props.timeLeft>13)? "0px" : "100%";
    var padding = (props.timeLeft>13)? "0px" : "20px";
    var fs = (props.timeLeft>13)? "0" : "1em";
    return (
        <div className="footer" style={{height: height,paddingTop: padding,paddingBottom: padding, fontSize:fs, width: "100%"}}>
            <h2>Publications</h2>
            <div className="footText">
            <ol>
<li>(with F. Manin and B. Schweinhart) Topology and local geometry of the Eden model.<br/>
Submitted, preprint arXiv:2005.12349.</li>
<li>(with M. Kahle and E. Paquette) The fundamental group of 2-dimensional random cubical complexes.<br/>
Submitted, preprint arXiv:2005.12349.</li>
<li>(with R. Karpman) Isotopy graphs of Latin tableaux.<br/>
To appear in Advances in Applied Mathematics (accepted in March 2021).</li>
<li>(with H. Alpert) Art gallery problem with rook and queen vision.<br/>
Graphs and Combinatorics, 37(2), 621-642, Feb 2021.</li>
<li>(with G. Malen) Polyiamonds Attaining Extremal Topological Properties II, Part II.<br/>
Geombinatorics, Volume XXX, Issue 2, pages 64-76, Oct 2020.</li>
<li>(with G. Malen) Polyiamonds Attaining Extremal Topological Properties I, Part I.<br/>
Geombinatorics, Volume XXX, Issue 1, pages 5-15, Jul 2020.</li>
<li>(with G. Malen) Extremal topological and geometric problems for polyominoes.<br/>
Electronic Journal of Combinatorics, Jun 2020.</li>
<li>(with M. Kahle) Polyominoes with maximally many holes.<br/>
Geombinatorics, Volume XXIX, Issue 1, pages 5-15, Jul 2019.</li>
</ol>
        </div>
        </div>
    );
}

function Top(props) {

    return (
        <div className="Top" style={{width: props.size.width*0.9}}>
            <Menu timeLeft={props.timeLeft} size={props.size} gotoNext={props.gotoNext}/>
            <Right timeLeft={props.timeLeft}/>
        </div>
    );
}

function Right(props) {
    var hide = (props.timeLeft > 15);
    var opacity = hide ? 0.01 : (15-props.timeLeft)/15;

    return (
        <div className="right"
             style={{marginLeft: hide ? "0px" : "20px",
                     width: hide ? "0px" : "480px",
                     opacity: opacity}}>
            {!hide &&
                <div>
                    <p>I am currently a Marie Skłodowska-Curie Fellow at Technical University Munich and École Polytechnique Fédérale de Lausanne. <a href="http://postdoc.eurotech-universities.eu/our-fellows/#accordion-1-t62" target="_blank" rel="noreferrer">Project: Topological and Geometric Data Analysis of Random Growth Models</a>
                        <br/><br/>Previously, I was a <a href="https://math.osu.edu/osu-department-mathematics-newsletter/spring-2019/faculty-spotlight-erika-roldan-roa" target="_blank" rel="noreferrer">Visiting Assistant Professor</a> at The Ohio State University in the Department of Mathematics working with the <a href="https://tgda.osu.edu" target="_blank" rel="noreferrer">Topology, Geometry, and Data Analysis (TDGA)</a> research group.
                        <br/><br/>I got my PhD in May 2018 at the Center for Research in Mathematics (CIMAT). My advisors were <a href="http://www.matthewkahle.org" target="_blank" rel="noreferrer">Matthew Kahle</a> (OSU) and Víctor Pérez-Abreu <a href="https://www.cimat.mx/" target="_blank" rel="noreferrer">(CIMAT)</a>.
                        <br/><br/>My research interests include stochastic topology, extremal topological combinatorics, topological and geometric data analysis, configuration spaces, and recreational mathematics.
                    </p>
                </div>
            }
        </div>
    );
}

export default Main;
export {Header};
export {Footer};